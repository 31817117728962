import { useState, useEffect } from "react";
import styles from "./Header.module.scss";
import DownCaret from "../Assets/Svg/DownCaret.jsx";

const getCountryCode = (locale) => {

  const languageCode = locale?.split("-")?.[0];
  switch (languageCode) {
    case "en":
      return "gb";
    case "sv":
      return "se";
    case "nb":
      return "no";
    case "it":
      return "it";
    case "fr":
      return "fr";
    case "fi":
      return "fi";
    case "pl":
      return "pl";
    default:
      return null;
  }
};

const LocaleSelector = ({ locales, currentLocale }) => {
  const [open, setOpen] = useState(false);

  if (!locales || !currentLocale) {
    console.error(
      "Content is undefined in Header LocaleSelector, returning nothing to UI. Try adding content to the block."
    );
    return null;
  }

  const localeNames = {
    sv: "Sweden | Sverige",
    en: "United Kingdom",
    nb: "Norway | Norge",
    it: "Italy | Italia",
    fi: "Finland | Suomi",
    fr: "France",
    pl: "Poland | Polska",
    "": "United Kingdom",
  };

  const updatedLocales = locales.map((locale) => {
    return {
      ...locale,
      name: localeNames[locale.code] || locale.name,
    };
  });

  return (
    <div
      className={styles.localesWrapper}
      onClick={() => {
        setOpen(!open);
      }}
    >
      <div className={styles.currentLocaleWrapper}>
        <p>{currentLocale}</p>
        <span>
          <DownCaret />
        </span>
      </div>
      {open && (
        <div className={styles.localesMenu}>
          <div className={styles.localesMenuHeader}>
            <p>Choose location</p>
          </div>
          {updatedLocales
            .filter((locale) => locale?.code !== currentLocale)
            .map((locale) => (
              <a href={`/${locale?.code}`} key={locale?.code}>
                {locale?.name}
              </a>
            ))}
        </div>
      )}
      {open && (
        <div
          onClick={() => setOpen(false)}
          className={styles.localesMenuOverlay}
        />
      )}
    </div>
  );
};

export const HeaderComponent = ({ locales = [], currentLocale = 'en', menuLinks = [],site={},host }) => {
  const [scrollY, setScrollY] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);

  if (!menuLinks) {
    console.error(
      "Content is undefined in Header HeaderComponent MenuItems, returning nothing to UI. Try adding content to the block."
    );
    return null;
  } else if (!locales || !currentLocale) {
    console.error(
      "Content is undefined in Header HeaderComponent Locales, returning nothing to UI. Try adding content to the block."
    );
    return null;
  }

  useEffect(() => {
    const threshold = 0;
    let lastScrollY = window.scrollY;
    let ticking = false;

    if (Math.abs(scrollY !== lastScrollY)) {
      setScrollY(window.scrollY);
    }

    const updateScrollDir = () => {
      const scrollY = window.scrollY;

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }

      setScrollY(scrollY);
      
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);


  return (
    <div
      className={styles.header}
      style={{ background: `rgb(255, 255, 255, ${(scrollY / 500)+.8})` }}
    >
      <div className={styles.logo}>
        <a href={`/${currentLocale}`}>
          <img src={`${host}${site.attributes.Logo.data.attributes.url}`}/>

        </a>
      </div>
      <div className={styles.linksWrapper}>
        <div className={styles.links}>


        {
            Array.isArray(menuLinks) && menuLinks.map((link) => {
                    return (
                        <a href={`/${currentLocale}/${link.attributes.Slug}`} key={link.attributes.Title}>
                        {link.attributes.Title}
                        </a>
                    );
            })
        }


        </div>
        {/* {locales && (
          <LocaleSelector locales={locales} currentLocale={currentLocale} />
        )} */}
      </div>


    </div>
  );
};
